<template>
  <div class="w-100 px-3">
    <div class="colon-broom-info"><div class="colon-broom-image-dev"><img data-src="https://images.quizell.com/website/colonbroomlogo.svg" src="https://images.quizell.com/website/colonbroomlogo.svg" class="colon-broom-image ls-is-cached lazyloaded"></div></div>
    <LazyImage class=" d-none d-md-block" src="https://images.quizell.com/colon-broom/image%20(32).png" style="position: fixed; top: 33%; right: 0px;" ></LazyImage>
    <LazyImage class=" d-none d-md-block" src="https://images.quizell.com/colon-broom/image%20(31).png" style="position: fixed; bottom: 38%; left: 0px;" ></LazyImage>
  <div class="d-flex align-items-center justify-content-center flex-column mt-100" style="gap:24px">
    
      <div class="double-quote">”</div>
    <div class="colon-broom-comment-section position-relative">
<div class="d-flex align-items-center justify-content-center flex-column" style="gap:16px">
    <div class="colon-broom-stars d-flex"  style="gap:8px"><i class="fa-solid fa-star" v-for="index in 5" style="color: #F7B500;" :key="index"></i></div>
    <div class="">
        <div class="colon-broom-comment-heading">
            This stuff is amazing
        </div>
        <div class="colon-broom-comment">
            This stuff is amazing! I’ve had moderate gut issues for quite some time. I’ve been taking Colon Broom for about 2 weeks now, and already feeling better. Since taking Colon Broom, I’m more regular, less bloated, and I have fewer cravings for sweets and junk food. Plus, this tastes SO GOOD.
        </div>
    </div>
</div>
    </div>
    <div class="">
        <span class="comment-username">Abby</span>
        <span class="comment-user-status">
            <i class="fa-solid fa-circle-check"></i>
            Verified Customer
        </span>
    </div>
    <div class="mt-100 colon-broom-loader-section">
        <div class="colon-broom-loader-text ">
            Creating your agenda...
        </div>
        <div class="colon-broom-loader"></div>
    </div>
  </div>
 
  </div>
</template>

<script>
export default {

}
</script>

<style>

.comment-username{
    color: #222;
font-family: Inter;
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: 24px; /* 150% */
margin-right: 12px;
}
.comment-user-status{
    color: #00B67A;
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 24px; /* 171.429% */
}
.colon-broom-info {
    max-width: 1200px;
    padding: 24px 24px 32px;
    margin: 0px auto;
    text-align: center;
}
.colon-broom-comment-heading{
    color: #222;
text-align: center;
font-family: Recoleta , "Poppins";
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: 24px; /* 120% */
    }
@media (min-width: 768px) {
    .colon-broom-info {
        padding: 34px 24px 56px;
    }
    .colon-broom-comment-heading{
        color: #222;
text-align: center;

font-family: Recoleta , "Poppins";
font-size: 20px;
font-style: normal;
font-weight: 500;
line-height: 24px; /* 150% */
    }
   
}
.colon-broom-comment-section{
    display: flex;
max-width: 552px;
flex-direction: column;
justify-content: center;
align-items: center;
gap: 24px;
}
.colon-broom-comment{
    color: #404040;
text-align: center;
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 24px;
text-align: justify;
text-align-last: center;
margin-top:8px;
    }
@media (max-width:768) and (min-width: 553px)
{
    .double-quote{
        font-size: 196.04px!important;
font-style: normal!important;
font-weight: 700!important;
line-height: 247.5px!important;
   
right: 100px!important;
    bottom: -30px!important;
}
}
@media (max-width:400px){
    .double-quote{
        font-size: 196.04px!important;
        right: 0%!important;
        top: 38px!important;
}
}
    @media (max-width: 768px) {
        .colon-broom-comment{
font-size: 12px;
font-weight: 400;
line-height: 22px; 
letter-spacing: 0.24px;
        }
        .colon-broom-loader-text{
    font-size: 18px;
font-style: normal;
font-weight: 500;
line-height: 24px; /* 133.333% */
}
.mt-100{
        margin-top:70px!important;
    }
   
    
    .colon-broom-loader-section{
        width: 100%!important;
    }
    .colon-broom-loader-text{
        font-size: 18px!important;
font-style: normal;
font-weight: 500;
line-height: 24px!important; 
    }
    }
    .mt-100{
        margin-top:100px;
    }
    .colon-broom-loader-section{
        width: 300px;
    }
    .colon-broom-loader-text {
    color: #0B1317;
    text-align: center;
    font-feature-settings: 'liga' off, 'clig' off;
    font-family: Recoleta, "Poppins";
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 32px;
    margin-bottom: 24px;
}

    .colon-broom-loader {
    display: block;
    --height-of-loader: 8px;
    --loader-color: #22BF8E;
    
    height: var(--height-of-loader);
    border-radius: 30px;
    background-color: #E8F6F2;
    position: relative;
}

.colon-broom-loader::before {
  content: "";
  position: absolute;
  background: var(--loader-color);
  top: 0;
  left: 0;
  width: 0%;
  height: 100%;
  border-radius: 30px;
  animation: moving 1.5s ease-in-out infinite;
  ;
}
.double-quote {
    color: rgba(34, 34, 34, 0.04);
    text-align: center;
    font-feature-settings: 'liga' off, 'clig' off;
    font-family: Recoleta;
    font-size: 280px;
    font-style: normal;
    font-weight: 700;
    line-height: 357.75px;
    position: absolute;
    right: 27%;
    top: 120px;
}
@keyframes moving {
  50% {
    width: 100%;
  }

  100% {
    width: 0;
    right: 0;
    left: unset;
  }
}
</style>